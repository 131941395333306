.container {
  display: flex;
  flex: 1 0 100%;
  flex-direction: column;
  justify-content: flex-end;
  & > a:only-child {
    flex-grow: 0;
  }
  & > a {
    flex-grow: 1;
  }
  @media screen and (min-width: 45em) {
    flex-direction: row;
  }
}

.nextPageButton {
  margin: 1rem;

  @media screen and (min-width: 45em) {
    margin: 2rem 2rem 1rem 2rem;
  }
}
